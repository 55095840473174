import React from "react";
import { Col, Row } from "react-bootstrap";
import "../css/navigation.css";

interface NavigationProps{
    LogoImage: string;
    BackgroundColour: string;
    AppName: string;
    BackgroundCircle: string;
    NavigationTextColor: string;
    Sticky?: boolean;
}

export default function Navigation(Props:NavigationProps){
    return (
        <Row className={Props.Sticky ? "justify-content-md-center navigation-main navigation-sticky" : "justify-content-md-center navigation-main"} style={{backgroundColor: Props.BackgroundColour}}>
            <Col xs={2}>
                <div className="navigation-logo" style={{backgroundImage: "url("+Props.LogoImage+")", backgroundColor: Props.BackgroundCircle}}></div>
                <div className="navigation-name" style={{color: Props.NavigationTextColor}}> {Props.AppName} </div>
            </Col>
        </Row>
    );
}