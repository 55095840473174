import React from "react";
import {Container} from "react-bootstrap";

import LogoImage from "../icon/temp/temp-icon-colour.png";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

export default function MainPage (){
    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#303030" 
                BackgroundCircle="#FFFFFF" 
                NavigationTextColor="#FFFFFF" 
                AppName="Liturgy App"></Navigation>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#E2FFE2" 
                BackgroundCircle="#303030" 
                NavigationTextColor="#303030" 
                AppName="Service App"></Navigation>
            <Footer
                CreditText="Developed by Sheepgate Church Toronto @ 2024 sheepgatetoronto.ca"
                BackgroundColor="#303030"
                FooterTextColor="#FFFFFF"></Footer>
        </Container>
    );
}
