import React from 'react';
import { Col, Row } from "react-bootstrap";
import "../css/footer.css";

interface FooterProps{
    CreditText: string;
    BackgroundColor: string;
    FooterTextColor: string;
}

export default function Footer(Props:FooterProps){
    return (
        <Row 
            className="justify-content-md-center footer-main footer-sticky" 
            style={{backgroundColor: Props.BackgroundColor}}>
            <Col xs={6}>
                <div 
                    className="footer-credit-text" 
                    style={{color: Props.FooterTextColor}}>
                    {Props.CreditText}
                </div>
            </Col>
        </Row>
    );
}
