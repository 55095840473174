import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/index.css";

import MainPage from './pages/MainPage';
import Homepage from './pages/Homepage';

const router = createBrowserRouter([
	{
		path: "/main",
		element: <MainPage/>,
	},
	{
		path: "/",
		element: <Homepage/>,
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router}/>
	</React.StrictMode>
);