import React from "react";
import {Container} from "react-bootstrap";

import LogoImage from "../icon/temp/temp-icon-colour.png";
import Navigation from "../components/Navigation";

export default function Homepage (){
    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#303030" 
                BackgroundCircle="#FFFFFF" 
                NavigationTextColor="#FFFFFF" 
                AppName="Liturgy App"></Navigation>
        </Container>
    );
}